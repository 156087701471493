









































































































































































































































































import Base from '@/mixins/Base.vue';
import { ITip, ITipsResponse } from '@/interfaces/tip';
import { IPagination } from '@/interfaces/pagination';

const component = Base.extend({
    data() {
        return {
            params: {
                is_published: null,
                source: null,
                title: '',
                sortBy: 'id',
                orderBy: 'desc',
                page: 1,

            },

            tips: {
                data: [] as Array<ITip>,
                meta: {} as IPagination | any,
            } as ITipsResponse,
        };
    },
    mounted() {
        this.getTips();
    },
    methods: {
        getTips(page: number = 1): void {
            this.params.page = page;

            this.get<ITipsResponse>('tips', {
                params: {
                    ...this.params,
                },
            }).then(({ data }) => {
                this.tips = data;
            });
        },
    },
});

export default component;
